.btn {
  height: 40px;
  width: 150px;
  color: $black1;
  font-family: $font2;
  font-weight: 900;
  font-size: 1rem;
  letter-spacing: 3px;
  border: 2px solid $black1;
  background: $white1;
  cursor: pointer;
  transition: all 0.5s ease-in-out;

  &:hover {
    background: $black1;
    color: $white1;
  }
}
