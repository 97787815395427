.contact-container {
  display: flex;
  flex-wrap: wrap;
  height: auto;
  margin: 20px;
  background: $white2;
  border-radius: 5px;
  max-width: 100vw;
  overflow: hidden;

  @media screen and (max-width: 900px) {
    flex-direction: column;
  }

  .info-contact-container {
    background: url(../../img/contact-img.webp) no-repeat center/cover;
    border-radius: 5px 0 0 5px;
    flex: 1 0 40%;
    height: auto;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-weight: bold;
    overflow: hidden;

    @media screen and (max-width: 937px) {
      border-radius: 5px 5px 0 0;
    }

    h3 {
      font-family: $font1;
      font-size: 2.5rem;
      margin-bottom: 40px;

      @media screen and (max-width: 937px) {
        margin-top: 40px;
      }
    }

    p {
      margin: 10px;
      font-size: 1.5rem;
    }

    ul {
      font-size: 1.5rem;
      display: flex;

      li {
        list-style: none;
        margin: 30px;

        i {
          font-size: 2rem;
          color: $black2;
          transition: all 0.3s ease-in-out;

          &:hover {
            transform: scale(1.2);
          }
        }
      }
    }
  }

  .form-container {
    max-width: 100vw;
    display: flex;
    align-items: center;
    flex-direction: column;
    flex: 1 0 60%;

    h2 {
      font-family: $font1;
      font-size: 2.5rem;
      margin: 35px 0 30px 0;
    }

    .submit-btn {
      height: 30px;
      width: 150px;
      color: $black1;
      font-family: $font2;
      font-weight: 700;
      font-size: 0.8rem;
      margin: 40px 0;
      letter-spacing: 3px;
      border: 1px solid $black1;
      background: $white1;

      cursor: pointer;
      transition: all 0.5s ease-in-out;

      &:hover {
        background: $black1;
        color: $white1;
      }
    }
    .form-content {
      display: flex;
      align-items: center;
      flex-direction: column;
      width: 100%;

      textarea {
        width: 70%;
        resize: none;
        height: 100px;
      }

      > * {
        width: 70%;
        padding: 20px 0;
        font-size: 0.9rem;
        font-weight: 500;
        height: 60px;
        margin: 20px;
        background: none;
        outline: none;
        border: none;
        border-bottom: 1px solid $grey1;
      }
    }
  }
}
