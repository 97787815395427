.card-container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 1 0 30%;
  flex-direction: column;
  margin: 20px;
  background: $white2;
  border-radius: 5px;
  transition: all 0.5s ease-in-out;

  &:hover {
    transform: translateY(-5px);
    box-shadow: 0 0 15px rgba(0, 0, 0, 0.3);
  }

  img {
    width: 100%;
    height: auto;
    border-radius: 5px 5px 0 0;
  }

  h3 {
    letter-spacing: 2px;
    margin-top: 30px;
  }

  p {
    width: 100%;
    padding: 20px;
    letter-spacing: 1px;
  }

  .link-container {
    margin: 20px 0 35px 0;
    height: 25px;
    width: 150px;
    border: 2px solid $black1;
    background: $white2;
    transition: all 0.5s ease-in-out;
    text-align: center;

    &:hover {
      background: $black1;

      i,
      a {
        color: $white2;
      }
    }

    a {
      display: block;
      width: 100%;
      color: $black1;
      font-family: $font2;
      font-weight: 600;
      font-size: 1rem;
      letter-spacing: 3px;
      cursor: pointer;
      text-decoration: none;

      i {
        font-size: 0.75rem;
        margin-left: 8px;
      }
    }
  }
}
