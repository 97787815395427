.language-switcher {
  z-index: 9999;
  position: fixed;
  bottom: 10px;
  left: 15px;

  &:hover {
    transform: translateY(-2px);
  }

  .language-switcher__toggle {
    position: relative;
    cursor: pointer;

    .language-switcher__flag {
      width: 30px;
      height: auto;
      margin-right: 10px;
      cursor: pointer;
    }

    .language-switcher__menu {
      display: none;
      position: absolute;
      bottom: 100%;
      right: 10px;
      padding: 3px;
      background-color: #fff;
      box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.3);
      z-index: 1;

      .language-switcher__menu-flag {
        width: 100%;
        height: auto;
        margin-right: 10px;
      }

      li {
        cursor: pointer;
        list-style: none;
      }
    }

    &:hover .language-switcher__menu,
    &.show .language-switcher__menu {
      display: block;
    }
  }
}
