.gallery-container {
  height: 400px;
  display: flex;
  flex-direction: column;
  align-items: end;
  align-items: center;

  h2 {
    text-align: center;
    margin: 0 auto;
    font-family: $font1;
    font-size: 3rem;
    padding-top: 20px;

    &::after,
    &::before {
      content: "";
      height: 2px;
      margin: 0 30px;
      background-color: $black1;
      display: inline-block;
      vertical-align: middle;
      width: 20rem;
      transition: all 0.3s ease-in-out;

      @media screen and (max-width: 1000px) {
        max-width: 15rem;
      }

      @media screen and (max-width: 850px) {
        max-width: 10rem;
      }

      @media screen and (max-width: 700px) {
        max-width: 5rem;
      }

      @media screen and (max-width: 450px) {
        max-width: 3rem;
        margin: 0 10px;
      }

      @media screen and (max-width: 300px) {
        max-width: 2rem;
        margin: 0 10px;
      }
    }
  }

  .main-slide {
    margin: 50px 20px 20px 20px;
    max-height: 50vh;
    border-radius: 5px;

    .thumbs {
      width: 350px;
      max-width: 100vw;
    }

    .carousel {
      max-width: 100vw;
      .control-prev::before {
        content: "\27A4";
        color: rgb(0, 0, 0);
        transform: rotate(180deg);
        font-size: 2rem;
        border: 0px;
        @media screen and (max-width: 450px) {
          display: none;
        }
      }
      .control-next::before {
        font-size: 2rem;
        content: "\27A4";
        color: $black2;
        border: 0px;
        @media screen and (max-width: 450px) {
          display: none;
        }
      }
      .slider {
        display: flex;
        align-items: center;
        .slide img {
          max-height: 500px;
          object-fit: contain;
          width: 100%;
          height: auto;
          background-color: $white1;
          border-radius: 5px;
          overflow: hidden;
        }
      }
    }
  }
}
