.home-container {
  display: flex;
  flex-wrap: wrap;
  overflow: hidden;

  .bg-img {
    background: url(../../img/hearder-img.jpg) center/cover no-repeat;
    flex: 1 0 30%;
    min-width: 480px;
    max-width: 100vw;
    height: 100vh;
    transition: all 0.5s ease-in-out;

    @media screen and (max-width: 977px) {
      background: url(../../img/hearder-img.jpg) top/contain no-repeat;
      min-width: none;
      height: 55vh;
    }

    @media screen and (max-width: 720px) {
      background: url(../../img/hearder-img.jpg) top/contain no-repeat;
      min-width: none;
      height: 60vh;
    }
    @media screen and (max-width: 600px) {
      background: url(../../img/hearder-img.jpg) top/contain no-repeat;
      min-width: none;
      height: 50vh;
    }
    @media screen and (max-width: 500px) {
      background: url(../../img/hearder-img.jpg) top/contain no-repeat;
      min-width: 100%;
      height: 50vh;
    }
    @media screen and (max-width: 450px) {
      background: url(../../img/hearder-img.jpg) top/contain no-repeat;
      min-width: 100%;
      height: 50vh;
      margin-bottom: -40px;
    }
    @media screen and (max-width: 400px) {
      background: url(../../img/hearder-img.jpg) top/contain no-repeat;
      min-width: 100%;
      height: 50vh;
      margin-bottom: -60px;
    }
    @media screen and (max-width: 335px) {
      background: url(../../img/hearder-img.jpg) top/contain no-repeat;
      min-width: 100%;
      height: 50vh;
      margin-bottom: -100px;
    }
  }

  .header-container {
    display: flex;
    flex: 1 0 50%;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 10px;

    @media screen and (max-width: 977px) {
      margin: -70px 0 40px 0;
    }

    h1 {
      position: relative;
      font-family: $font1;
      font-size: 4rem;
      font-weight: 700;
      color: $grey1;
      width: 100%;
      right: 57%;
      white-space: nowrap;
      margin-bottom: 40px;
      transition: all 0.8s ease-in-out;

      @media screen and (min-width: 2000px) {
        left: -54%;
        width: 50%;
        top: 0;
      }

      @media screen and (max-width: 977px) {
        left: 20%;
        width: 50%;
        top: -200px;
      }

      @media screen and (max-width: 720px) {
        font-size: 2.5rem;
        top: -150px;
      }

      @media screen and (max-width: 450px) {
        font-size: 1.8rem;
        top: -100px;
      }
      @media screen and (max-width: 310px) {
        font-size: 1.6rem;
        top: -100px;
        left: 0;
      }
    }

    h2 {
      font-size: 1.8rem;
      margin-bottom: 30px;
      font-family: $font1;
    }

    p {
      font-size: 1.1rem;
      width: 80%;
      max-width: 450px;
      margin-bottom: 30px;
    }
  }
}
