.up-btn {
  display: none;
  position: fixed;
  bottom: 30px;
  right: 30px;
  z-index: 999;
  padding: 10px;
  font-size: 16px;
  background-color: #f0f0f0;
  color: #444;
  border: none;
  border-radius: 6px;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.2);

  &:hover {
    transform: translateY(-3px);
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
  }

  &::before {
    content: "\f077";
    font-family: "Font Awesome 5 Free";
    font-weight: 900;
    font-size: 16px;
    color: #444;
  }
}
.up-btn.visible {
  display: block;
}
