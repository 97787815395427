.footer-container {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  margin-top: 400px;
  min-height: 75px;
  height: auto;
  width: auto;
  background: $white2;

  @media screen and (max-width: 600px) {
    margin-top: 300px;
  }

  .right-footer {
    color: $black1;
    font-size: 1.2rem;
    p {
      @media screen and (max-width: 800px) {
        margin-bottom: 15px;
      }
    }
  }

  .left-footer {
    ul {
      display: flex;

      li {
        display: flex;
        font-size: 1.2rem;
        align-items: center;
        list-style: none;
        margin: 0 30px;

        i {
          margin-left: 10px;
          font-size: 1.5rem;
          color: $black1;
          transition: all 0.3s ease-in-out;

          &:hover {
            transform: scale(1.2);
          }
        }
      }
    }
    @media screen and (max-width: 800px) {
      margin: 15px;
    }
  }

  @media screen and (max-width: 800px) {
    flex-direction: column;
  }
}
